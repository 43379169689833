import React, { useState, useEffect } from "react";
import styled from "styled-components";

import NavBar from "../components/NavBar";
import LinkButton from "../components/LinkButton";
import Image_employers from "../../src/images/Image_employers.png";
import Image_job_seekers from "../../src/images/Image_job_seekers.png";

import getTranslation from "../utils/getTranslation";

const OuterContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
`;

const InnerContainer = styled.main`
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 68px;
  ${(props) => `
        @media (max-width: ${props.theme.lg}) {
            flex-direction: column;
        }
  `};
`;

const LeftRightContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-width: 50%;
  padding: 0 72px;
  box-sizing: border-box;
  &.job-seeker {
    background-color: ${(props) => props.theme.lightestBlue};
  }
  ${(props) => `
        @media (max-width: ${props.theme.lg}) {
            width: 100%;
            min-height: calc(100vh - 68px);
            padding: 0 16px;
        }
  `};
`;

const HeaderContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const TagLine = styled.h1`
  color: ${(props) => props.theme.black};
  font-family: ${(props) => props.theme.fontFamilyBold};
  font-size: 2rem;
  line-height: 1.36;
  min-height: 2.72em;
  margin: 0;
  ${(props) => `
        @media (max-width: ${props.theme.lg}) {
            font-size: 1.875rem;
        }
  `};
`;

const Header = styled.h2`
  color: ${(props) => props.theme.blue};
  font-family: ${(props) => props.theme.fontFamilyBold};
  font-size: 0.875rem;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 4px;
  ${(props) => `
        @media (max-width: ${props.theme.lg}) {
            font-size: 0.813rem;
        }
  `};
`;

const ButtonContainer = styled.div`
  display: flex;
  > * {
    margin: 0 16px;
    min-width: 169px;
  }
  ${(props) => `
        @media (max-width: ${props.theme.lg}) {
            > * {
              min-width: fit-content;
              margin: 0 8px;
            }
        }
  `};
`;

const ImageContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  &.job-seekers {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  img {
    width: 100%;
  }
`;

const SpacerDiv = styled.div`
  &.first {
    flex-grow: 0.5;
  }
  &.second {
    flex-grow: 1;
  }
  &.third {
    flex-grow: 2;
  }
`;

const App = () => {
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    let url = window.location.href;
    if (url.includes("fr")) {
      setLanguage("fr");
      //setting language in html tag
      document &&
        document.documentElement &&
        document.documentElement.setAttribute("lang", "fr");
    } else {
      setLanguage("en");
      //setting language in html tag
      document &&
        document.documentElement &&
        document.documentElement.setAttribute("lang", "en");
    }
  }, []);

  const translate = (text) => {
    return getTranslation(language, text);
  };

  return (
    <OuterContainer>
      <NavBar language={language} />
      <InnerContainer>
        <LeftRightContainer className="job-seeker">
          <SpacerDiv className="first" />
          <HeaderContainer>
            <Header>{translate("job_seekers")}</Header>
            <TagLine>{translate("job_seekers_tagline")}</TagLine>
          </HeaderContainer>
          <SpacerDiv className="first" />
          <LinkButton
            buttonText={translate("job_seekers_cta")}
            color="green"
            href={language === "fr" ? "https://jobs.wirkn.com/fr" : "https://jobs.wirkn.com"}
            id="landingPage-job-button"
          />
          <SpacerDiv className="second" />
          <ImageContainer className="job-seekers">
            <img src={Image_job_seekers} alt="job-seekers" />
          </ImageContainer>
          <SpacerDiv className="third" />
        </LeftRightContainer>
        <LeftRightContainer>
          <SpacerDiv className="first" />
          <HeaderContainer>
            <Header>{translate("employers")}</Header>
            <TagLine>{translate("employers_tagline")}</TagLine>
          </HeaderContainer>
          <SpacerDiv className="first" />
          <ButtonContainer>
            <LinkButton
              buttonText={translate("employers_cta_demo")}
              color={"blue"}
              href={
                language === "fr"
                  ? "https://www.wirkn.com/fr/reserver-une-demo/"
                  : "https://www.wirkn.com/schedule-a-demo/"
              }
              id="landingPage-employer-demo-button"
            />
            <LinkButton
              buttonText={translate("employers_cta_signin")}
              color={"outline-blue"}
              href={
                language === "fr"
                  ? "https://employers.wirkn.com?lang=fr"
                  : "https://employers.wirkn.com"
              }
              id="landingPage-employer-signin-button"
            />
          </ButtonContainer>
          <SpacerDiv className="second" />
          <ImageContainer>
            <img src={Image_employers} alt="employers screenshot example" />
          </ImageContainer>
          <SpacerDiv className="third" />
        </LeftRightContainer>
      </InnerContainer>
    </OuterContainer>
  );
};

export default App;
