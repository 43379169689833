import English from '../locales/en/common.json';
import French from '../locales/fr/common.json';

const getTranslation = (language, text) => {
    if (language === 'fr') {
        return French[text]
    } else {
        return English[text]
    }
}

export default getTranslation;