import React from 'react';
import styled from 'styled-components';

const StyledLink = styled.a`
    font-size: 1rem;
    font-family: ${props => props.theme.fontFamilyDemiBold};
    padding: 12px 24px;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    white-space: nowrap;
    &.green {
        background-color: ${props => props.theme.green};
    }
    &.blue {
        background-color: ${props => props.theme.blue};
    }
    &.outline-blue {
        background-color: white;
        color: ${props => props.theme.blue};
        border: 1px solid ${props => props.theme.blue};
    }
`;

const LinkButton = ({buttonText, href, color, id}) => (
    <StyledLink 
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={color}
        id={id}
    >
        {buttonText}
    </StyledLink>
);

export default LinkButton;