const theme = {
    //font
    fontFamily: 'Avenir Next',
    fontFamilyDemiBold: 'Avenir Next DemiBold',
    fontFamilyBold: 'Avenir Next Bold',
    //colors
    blue:'#1b76b6',
    wirknBlue: '#00A9E0',
    lightestBlue: '#F4F8FC',
    black: '#2B333B',
    lightGrey: '#DCE1E5',
    green: '#2D8565',
    // Responsive breakpoint
    lg: '1025px',
}

module.exports = theme;