import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import Logo from "./Logo";

import getTranslation from "../utils/getTranslation";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";

const FixedContainer = styled.nav`
  position: fixed;
  width: 100%;
  height: 68px;
  font-family: ${(props) => props.theme.fontFamilyDemiBold};
`;

const StyledNav = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => `
        @media (max-width: ${props.theme.lg}) {
            position: relative;
            background-color: ${props.theme.lightestBlue};
            &.open {
              background-color: white;
            }
        }
  `};
`;

const LeftNav = styled.div`
  width: 50%;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.lightestBlue};
  padding: 22px 60px;
  ${(props) => `
        @media (max-width: ${props.theme.lg}) {
            width: fit-content;
            padding: 23px 16px;
            background-color: unset;
        }
  `};
`;

const Hamburger = styled.button`
  display: none;
  ${(props) => `
        @media (max-width: ${props.theme.lg}) {
            display: unset;
            position: relative;
            margin-right: 10px; 
            background-color: unset;
            border: none;
            font-size: 1.625rem;
            padding: 0 16px;
            cursor: pointer;
        }
  `};
`;

const NavOptions = styled.div`
  padding-right: 60px;
  a {
    padding: 4px 12px;
    color: ${(props) => props.theme.black};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  ${(props) => `
        @media (max-width: ${props.theme.lg}) {
            display: none;
        }
  `};
`;

const BurgerMenu = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 100%;
  z-index: 18;
`;

const Backdrop = styled.div`
  display: none;
  ${(props) => `
        @media (max-width: ${props.theme.lg}) {
          display: flex;
          background-color: #2b333b;
          opacity: 0.5;
          height: 100%;
          width: 100%;
        }
  `};
`;

const NavOptionsMobile = styled.ul`
  display: none;
  ${(props) => `
        @media (max-width: ${props.theme.lg}) {
          display: inline-block;
          background-color: white;
          width: 100%;
          margin: 0;
          padding: 0;
          text-align: right;
          opacity: 1;
          li:last-child {
            margin-bottom: 27px;
          }
          div {
            margin: 0 16px;
            border-top: 1px solid ${props.theme.lightGrey};
            background-color: white;
          }
          li {
            list-style-type: none;
            margin: 16px 0;
          }
            a {
              color: ${props.theme.black};
              text-decoration: none;
              font-size: 1rem;
              line-height: 2;
              &:hover, &:focus {
                text-decoration: underline;
              }
            }
        }
  `};
`;

function NavBar({ language }) {
  let hamburgerOptions = 3;
  const closeRef = useRef();
  const itemsRef = useRef(Array(hamburgerOptions));

  const [navOpen, setNavOpen] = useState(false);
  const [focused, setFocused] = useState(-1);

  const openBurgerMenu = () => {
    document.body.style.overflow = "hidden";
    setNavOpen(true);
    setFocused(-1);
  };

  const closeBurgerMenu = () => {
    document.body.style.overflow = "unset";
    setNavOpen(false);
    setFocused(-1);
  };

  const handleKeyPress = (e) => {
    if (e.code === "ArrowDown") {
      if (focused < hamburgerOptions - 1) {
        itemsRef &&
          itemsRef.current &&
          itemsRef.current[focused + 1] &&
          itemsRef.current[focused + 1].focus();
        setFocused(focused + 1);
      }
    } else if (e.code === "ArrowUp") {
      if (focused <= 0) {
        closeRef && closeRef.current && closeRef.current.focus();
        setFocused(-1);
      } else {
        itemsRef &&
          itemsRef.current &&
          itemsRef.current[focused - 1] &&
          itemsRef.current[focused - 1].focus();
        setFocused(focused - 1);
      }
    }
    if (e.code === "Escape") {
      closeBurgerMenu();
    }
    if (e.code === "Space") {
      itemsRef &&
        itemsRef.current &&
        itemsRef.current[focused] &&
        itemsRef.current[focused].click();
    }
  };

  useEffect(() => {
    if (navOpen) {
      document.addEventListener("keydown", handleKeyPress);
    } else {
      document.removeEventListener("keydown", handleKeyPress);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  });

  const translate = (text) => {
    return getTranslation(language, text);
  };

  return (
    <FixedContainer>
      <StyledNav className={navOpen ? "open" : ""}>
        <LeftNav>
          <Logo />
        </LeftNav>
        {navOpen ? (
          <Hamburger
            ref={closeRef}
            aria-label="show navigation menu"
            aria-expanded="false"
            onClick={() => closeBurgerMenu()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Hamburger>
        ) : (
          <Hamburger
            aria-label="hide navigation menu"
            aria-expanded="true"
            onClick={() => openBurgerMenu()}
          >
            <FontAwesomeIcon icon={faBars} />
          </Hamburger>
        )}
        {navOpen && (
          <BurgerMenu onKeyPress={(e) => handleKeyPress(e)}>
            <NavOptionsMobile>
              <div>
                <li>
                  <a
                    ref={(el) => (itemsRef.current[0] = el)}
                    id="landingPage-jobSeeker-link"
                    href={
                      language === "fr"
                        ? "https://jobs.wirkn.com/fr"
                        : "https://jobs.wirkn.com"
                    }
                  >
                    {translate("job_seekers")}
                  </a>
                </li>
                <li>
                  <a
                    ref={(el) => (itemsRef.current[1] = el)}
                    id="landingPage-employer-link"
                    href={
                      language === "fr"
                        ? "https://employers.wirkn.com?lang=fr"
                        : "https://employers.wirkn.com"
                    }
                  >
                    {translate("employers")}
                  </a>
                </li>
                <li>
                  {language === "en" ? (
                    <a
                      ref={(el) => (itemsRef.current[2] = el)}
                      href={`${window.location.href}fr`}
                      id="landingPage-jobSeeker-link"
                    >
                      Francais
                    </a>
                  ) : (
                    <a
                      ref={(el) => (itemsRef.current[2] = el)}
                      href={window.location.href.replace("/fr", "")}
                    >
                      English
                    </a>
                  )}
                </li>
              </div>
            </NavOptionsMobile>
            <Backdrop onClick={() => closeBurgerMenu()} />
          </BurgerMenu>
        )}
        <NavOptions>
          <a
            href={
              language === "fr"
                ? "https://jobs.wirkn.com/fr"
                : "https://jobs.wirkn.com"
            }
            target="_blank"
            rel="noopener noreferrer"
            id="landingPage-jobSeeker-link"
          >
            {translate("job_seekers")}
          </a>
          <a
            href={
              language === "fr"
                ? "https://employers.wirkn.com?lang=fr"
                : "https://employers.wirkn.com"
            }
            target="_blank"
            rel="noopener noreferrer" 
            id="landingPage-employer-link"
          >
            {translate("employers")}
          </a>
          {language === "en" ? (
            <a href={`${window.location.href}fr`}>Francais</a>
          ) : (
            <a href={window.location.href.replace("/fr", "")}>English</a>
          )}
        </NavOptions>
      </StyledNav>
    </FixedContainer>
  );
}

export default NavBar;
